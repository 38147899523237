<!--
 * @Description: 任务单详情
 * @Author: ChenXueLin
 * @Date: 2021-08-17 10:32:32
 * @LastEditTime: 2023-03-09 17:46:06
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            :btnType="btnType"
            :btnText="'任务单状态:' + basicInfo.taskstatus"
            :time="basicInfo.followDateStr"
          >
            <!-- 标签 -->
            <template v-slot:leftButtons>
              <div
                class="danger-status"
                v-for="(item, index) in tags"
                :key="index"
              >
                {{ item }}
              </div>
            </template>
            <template v-slot:rightButtons>
              <el-button type="primary" @click="handleReason" v-has="103">
                超期原因
              </el-button>
              <el-button type="primary" @click="handleMaintenance" v-has="306">
                维护批量信息
              </el-button>
              <el-button
                type="primary"
                @click="openEditBaseInfoDailog"
                v-if="showEditBtn"
                v-has="EDIT_BUTTON_ID"
                >修改基础信息</el-button
              >
              <el-button
                type="primary"
                @click="addService"
                v-has="ADD_BUTTON_ID"
              >
                添加服务详情
              </el-button>
              <el-button
                type="primary"
                @click="handleSetTime"
                v-if="showTimeBtn"
                >设置跟进时间</el-button
              >
              <el-button
                type="primary"
                @click="closeTask('任务单')"
                v-if="
                  basicInfo.taskstatusCode == 1 || basicInfo.taskstatusCode == 2
                "
                v-has="LOGOUT_BUTTON_ID"
                >关闭任务单
              </el-button>
            </template>
          </detail-title-content>
          <div class="baseInfo-content-wrap">
            <div class="detailInfo-content">
              <el-tabs v-model="activeName">
                <!-- @tab-click="handleTabClick" -->
                <el-tab-pane label="基本信息" name="one">
                  <base-info
                    :basicInfo="basicInfo"
                    :taskId="taskId"
                    ref="basicInfoChild"
                  ></base-info>
                </el-tab-pane>
                <el-tab-pane label="调度任务" name="two">
                  <dispatcher-task
                    :tableHeight="tableHeight"
                    :type="activeName"
                    :taskId="taskId"
                    @refreshData="updateDetail"
                  ></dispatcher-task>
                </el-tab-pane>
                <el-tab-pane label="调度信息" name="three">
                  <!--  v-if="entry != 'G7Task'" -->
                  <dispatcher-info
                    :tableHeight="tableHeight"
                    :type="activeName"
                    :taskId="taskId"
                    @refreshData="getTaskBasicInfo"
                  ></dispatcher-info>
                </el-tab-pane>
                <el-tab-pane label="关联单据" name="four">
                  <tree-chart :json="treeData" />
                </el-tab-pane>
                <el-tab-pane label="费用明细" name="five">
                  <fee-detail-table
                    :show="activeName == 'five'"
                    :taskId="taskId"
                  ></fee-detail-table>
                </el-tab-pane>
                <el-tab-pane
                  label="出库信息"
                  name="six"
                  v-if="
                    basicInfo.outApplyNoList && basicInfo.outApplyNoList.length
                  "
                >
                  <outboundInfo
                    :tableHeight="tableHeight"
                    :type="activeName"
                    :show="activeName == 'six'"
                    :outApplyNo="basicInfo.outApplyNoList"
                  ></outboundInfo>
                </el-tab-pane>
                <el-tab-pane label="来去电记录" name="seven">
                  <phone-record
                    :tableHeight="tableHeight"
                    :type="activeName"
                    :taskId="taskId"
                  ></phone-record>
                </el-tab-pane>
              </el-tabs>
            </div>
            <!-- 右侧 服务详情 -->
            <div class="record-box">
              <el-tabs v-model="recordName">
                <el-tab-pane label="服务详情" name="one">
                  <div class="record-item-box">
                    <record-item :recordsList="recordsList"></record-item>
                  </div>
                </el-tab-pane>
                <!-- <el-tab-pane label="历史记录" name="second">
                  <div class="record-item-box">
                    <record-item
                      v-for="item in recordsList"
                      :key="item"
                    ></record-item>
                  </div>
                </el-tab-pane> -->
              </el-tabs>
            </div>
          </div>
          <!-- 基本信息页面 -->
        </div>
      </section>
      <!-- 表格 end -->
    </div>
    <!-- 添加服务详情弹框 -->
    <add-service-detail
      :taskId="taskId"
      :taskType="1"
      :addServiceDialog="addServiceDialog"
      @handleClose="handleClose"
      :customerId="basicInfo.customerId"
      :contact="basicInfo.corpContactName"
      :taskNum="basicInfo.taskNo"
      :basicInfo="basicInfo"
      :customerName="basicInfo.customerName"
      @getTaskServiceReq="getTaskServiceReq"
      v-if="addServiceDialog"
      :itemIdList="serviceItemList"
    ></add-service-detail>
    <!-- 设置跟进时间 -->
    <set-time
      :taskId="taskId"
      :setTimeDialog="setTimeDialog"
      @handleClose="handleClose"
      @getTaskBasicInfo="getTaskBasicInfo"
      v-if="setTimeDialog"
    ></set-time>
    <!-- 修改工单信息弹窗 -->
    <editBaseInfoDailog
      :taskId="taskId"
      task
      ref="editBaseInfoDailog"
      v-if="editBaseInfoDailogVisible"
      :taskSource="basicInfo.taskSource"
      @update="updateDetail"
    ></editBaseInfoDailog>
    <!-- 关闭任务单 -->
    <close-task-item-dialog
      :closeTaskItemDialog="closeTaskItemDialog"
      @handleClose="handleClose"
      :taskIdList="[taskId]"
      dialogType="2"
      @refresh="getTaskBasicInfo"
    ></close-task-item-dialog>
    <!-- 维护批量信息弹框 -->
    <maintenance-dialog
      :maintenanceVisible="maintenanceVisible"
      :taskIdList="[taskId]"
      @refresh="getTaskBasicInfo"
      @closeMaintenanceDialog="closeMaintenanceDialog"
    ></maintenance-dialog>
    <!-- 超期原因 -->
    <overdue-reason-dialog
      :itemIdList="itemIdList"
      :overdueReasonVisible="overdueReasonVisible"
      @refresh="updateDetail"
      @close="closeDialog"
    ></overdue-reason-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import setTime from "@/components/workOrderManage/setTime.vue";
import addServiceDetail from "@/components/workOrderManage/addServiceDetail.vue";
import detailTitleContent from "@/components/detailTitleContent";
import BaseInfo from "./page/baseInfo.vue";
import feeDetailTable from "@/components/workOrderManage/fee/feeDetailTable.vue";
import recordItem from "@/components/workOrderManage/recordItem.vue";
import DispatcherTask from "./page/dispatcherTask.vue";
import outboundInfo from "./page/outboundInfo.vue";
import phoneRecord from "./page/phoneRecord.vue";
import TreeChart from "@/components/treeChart";
import closeTaskItemDialog from "@/components/workOrderManage/closeTaskItemDialog.vue";
import eventBus from "@/bus";
import { getTaskBasicInfo, associatedBills, getTaskService } from "@/api";
import { printError } from "@/utils/util";
import taskDetail from "@/mixins/taskDetail";
import DispatcherInfo from "./page/dispatcherInfo.vue";
import editBaseInfoDailog from "@/views/WOS/workOrderManage/taskListManage/detail/components/editBaseInfoDailog";
import OverdueReasonDialog from "@/components/overdueReasonDialog/overdueReasonDialog.vue";
import maintenanceDialog from "@/components/maintenanceDialog/maintenanceDialog.vue";
import setting from "@/setting";
let { EDIT_BUTTON_ID, LOGOUT_BUTTON_ID, ADD_BUTTON_ID } = setting;
export default {
  name: "taskListDetail",
  mixins: [base, taskDetail],
  components: {
    recordItem,
    feeDetailTable,
    setTime,
    addServiceDetail,
    detailTitleContent,
    BaseInfo,
    DispatcherTask,
    TreeChart,
    DispatcherInfo,
    editBaseInfoDailog,
    outboundInfo,
    closeTaskItemDialog,
    maintenanceDialog,
    OverdueReasonDialog,
    phoneRecord,
    // AssociatedBills
  },
  data() {
    return {
      EDIT_BUTTON_ID,
      LOGOUT_BUTTON_ID,
      ADD_BUTTON_ID,
      recordName: "one",
      id: "",
      tableHeight: 300,
      closeTaskItemDialog: false,
      //工单状态1待处理2进行中3已完成4已关闭
      recordsList: [],
      loading: false,
      basicInfo: {},
      tags: [],
      activeName: "one",
      collectDialog: false,
      splieOrderDialog: false,
      editBaseInfoDailogVisible: false,
      treeData: [],
      taskType: 1, //1为任务单
      maintenanceVisible: false,
      overdueReasonVisible: false,
      itemIdList: [],
    };
  },
  computed: {
    btnType() {
      let value = "";
      if (this.basicInfo.taskstatusCode == 1) {
        value = "yellowBtn";
      }
      if (this.basicInfo.taskstatusCode == 2) {
        value = "blueBtn";
      }
      if (
        this.basicInfo.taskstatusCode == 3 ||
        this.basicInfo.taskstatusCode == 4
      ) {
        value = "greenBtn";
      }
      return value;
    },
    showEditBtn() {
      let flag = false;
      if (
        (this.basicInfo.taskstatusCode == 1 ||
          this.basicInfo.taskstatusCode == 2) &&
        this.$route.meta.operationIds.includes(71)
      ) {
        flag = true;
      }
      return flag;
    },
    showTimeBtn() {
      let flag = false;
      if (
        (this.basicInfo.taskstatusCode == 1 ||
          this.basicInfo.taskstatusCode == 2) &&
        this.$route.meta.operationIds.includes(122)
      ) {
        flag = true;
      }
      return flag;
    },
  },
  created() {
    this.initForm();
    eventBus.$on("reload", (id) => {
      this.reload(id);
    });
    eventBus.$on("changeServiceItemList", (data) => {
      this.serviceItemList = data;
    });
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  watch: {
    activeName: {
      immediate: true,
      handler(val) {
        if (val == "one" && this.taskId) {
          this.getTaskBasicInfo();
          this.getAssociatedBills();
        }
      },
    },
  },
  methods: {
    handleMaintenance() {
      this.maintenanceVisible = true;
    },
    closeMaintenanceDialog() {
      this.maintenanceVisible = false;
    },
    handleReason() {
      this.itemIdList = this.$refs.basicInfoChild.$refs.itemChild.itemIdList;
      if (!this.itemIdList.length) {
        this.$message.warning("请先勾选任务项");
        return;
      }
      this.overdueReasonVisible = true;
    },
    closeDialog() {
      this.overdueReasonVisible = false;
      this.$refs.basicInfoChild.$refs.itemChild.$refs.multipleTable.clearSelection();
    },
    // 打开基础信息修改弹窗
    openEditBaseInfoDailog() {
      this.editBaseInfoDailogVisible = true;
      this.$nextTick(() => {
        this.$refs.editBaseInfoDailog.init(this.basicInfo);
      });
    },
    //更新页面
    updateDetail() {
      this.getTaskBasicInfo();
      this.$refs.basicInfoChild.$refs.itemChild.updateItem();
    },
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.taskId = hasParamsRoute.params.taskId;
      this.id = hasParamsRoute.params.taskId;

      if (this.taskId) {
        this.getTaskBasicInfo();
        this.getAssociatedBills();
        this.getTaskServiceReq();
      }
      this.tableHeight = document.documentElement.clientHeight - 250;
      //刷新
      eventBus.$on("refreshTaskDetail", () => {
        this.getTaskBasicInfo();
      });
    },
    //获取基本信息
    async getTaskBasicInfo() {
      try {
        this.loading = true;
        let res = await getTaskBasicInfo({
          taskId: this.taskId,
        });
        //基本信息
        this.basicInfo = this.getFreezeResponse(res, {
          path: "data",
        });
        if (this.basicInfo.tags.length) {
          this.tags = this.basicInfo.tags.split(",");
        } else {
          this.tags = [];
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取服务详情
    async getTaskServiceReq() {
      try {
        // this.loading = true;
        let res = await getTaskService({
          id: this.taskId,
        });
        this.recordsList = this.getFreezeResponse(res, {
          path: "data",
        });
      } catch (error) {
        printError(error);
      }
    },

    //关闭任务单
    closeTask() {
      this.closeTaskItemDialog = true;
    },

    //创建任务单
    createTask() {
      this.routerPush({
        name: "workOrderManage/createTask",
        params: {
          refresh: true,
        },
      });
    },
    //获取关联单据
    async getAssociatedBills() {
      try {
        let res = await associatedBills({
          taskId: this.taskId,
        });
        //基本信息
        this.treeData = res.data;
      } catch (error) {
        printError(error);
      }
    },
    //点击单据跳转刷新
    reload(id) {
      this.activeName = "one";
      this.taskId = id;
      this.getTaskBasicInfo();
      this.getAssociatedBills();
      this.getTaskServiceReq();
      this.tableHeight = document.documentElement.clientHeight - 250;
      //刷新
      eventBus.$on("refreshTaskDetail", () => {
        this.getTaskBasicInfo();
      });
    },
  },
  beforeDestroy() {
    eventBus.$off("refreshTaskDetail");
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../../assets/styles/index.scss";
.main-wrapper > .container-wrapper .content-view-wrapper .content-view {
  padding: 0px;
}
.main-wrapper .edit-wrapper .edit-wrapper__body {
  height: 100%;
}
.baseInfo-content-wrap {
  display: flex;
  width: 100%;
  height: calc(100% - 50px);
  position: relative;
  .detailInfo-content {
    width: calc(100% - 300px);
    height: 100%;
    border-right: 1px solid #f0f0f0;
    box-sizing: border-box;
    overflow-y: auto;
  }
  .task-list-content {
    .table-title-btn {
      height: 50px;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 50px;
      font-size: 14px;
      box-sizing: border-box;
      color: #303133;
      border-width: 1px 0;
      border-style: solid;
      border-color: #f5f7fa;
      background-color: #f9fafc;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      i {
        margin-left: 10px;
        height: 28px;
        margin-right: 10px;
        color: $--color-primary;
        font-size: 12px;
        line-height: 28px;
        font-style: normal;
        background-color: $--color-primary-light-8; // #daf2fb
        text-align: center;
        padding: 0 10px;
        cursor: pointer;
        border-radius: 3px;
      }
      [class^="e6-icon"] {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        padding: 0;
        font-size: 16px;
      }
    }
    .search-list {
      background: #fff !important;
    }
    .label-button-box {
      width: 100%;
      padding: 5px 10px;
      box-sizing: border-box;
    }
  }
  .record-box {
    width: 300px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
  }
}
/deep/.el-tab-pane {
  width: 100%;
  overflow-x: auto !important;
}
</style>
